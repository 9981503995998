<template>
    <SearchModal heading="Search for staff directory of building" :loading="$store.getters.loading">

        <form slot="form" class="form-row" @submit.prevent="requestBuildingStaff()">
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="6">
                        <v-select
                            :items="$store.getters.campuses"
                            item-text="Campus Name"
                            item-value="Campus Code"
                            label="Select a campus"
                            v-model="campus"
                            autofocus 
                            @change="requestBuildings()"
                            required
                            ></v-select>
                    </v-col>

                    <v-col cols="9" md="5">
                        <v-select
                            :items="$store.getters.buildings.map((b) => {
                                if (b['Building Code'] == b['Building Name']) {
                                    return {
                                        text: b['Building Name'],
                                        value: b['Building Code']
                                    }
                                }

                                return {
                                    text: `(${b['Building Code']}) ${b['Building Name']}`,
                                    value: b['Building Code']
                                }
                            })"
                            label="Search building"
                            v-model="building"
                            ></v-select>
                    </v-col>

                    <v-col cols="3" md="1">
                        <v-btn color="#903"
                            type="submit"
                            @click="requestBuildingStaff()"
                            width="100%">
                            <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            
        </form>
    </SearchModal>
</template>

<script>
    import {getCampuses, getBuildings} from '../lib/api'
    import {debugMsg} from '../lib/helpers';

    import SearchModal from '../components/layouts/SearchModal'
    export default {
        name: "buildings-page",
            components: {
            SearchModal,
        },

        data: () => ({
            campus: "",
            building: "",
        }),

        methods: {
            /**
            * Request a list of availible campuses from the Staff directory API
            */
            requestCampus: function() {
                this.$store.commit('setLoading', true);

                getCampuses()
                .then((res) => {

                    this.$store.commit('setLoading', false);

                    // Check if API returned any results
                    if (res.data.length == 0) {
                        this.$store.commit("errorMsg", "No results were found");
                    } else {
                        // store result in vuex
                        this.$store.commit("updateCampuses", res);
                    }
                })
                .catch((err) => {
                    // display error message
                    debugMsg("Error requesting campuses: ", err)
                    this.$store.commit("errorMsg", `There was an error: ${err.msg}`);
                    this.$store.commit('setLoading', false);
                })
            },


            /**
            * Request a list of availible buildings based on a specified campus 
            * from the Staff directory API
            */
            requestBuildings: function() {
                this.$store.commit('setLoading', true);

                getBuildings(this.campus)
                    .then((res) => {
                        this.$store.commit('setLoading', false);

                        // Check if API returned any results
                        if (res.data.length == 0) {
                            this.$store.commit("errorMsg", "No results were found");
                        } else {
                            // store result in vuex
                            this.$store.commit("updateBuildings", res);
                        }
                    })
                    .catch((err) => {
                        // display error message
                        debugMsg("Error requesting campus buildings: ", err)
                        this.$store.commit("errorMsg", "There was an API error");
                        this.$store.commit('setLoading', false);


                        // Attempt to reconnect to the API in 2 mins
                        var vm = this;
                        setTimeout(() => {
                            vm.requestBuildings();
                        }, 12e4);
                    })
            },


            /**
            * Request a list of availible staff based on a specified campus & 
            * building from the Staff directory API
            */
            requestBuildingStaff: function() {
                this.$router.push({path: `/campus/${this.campus}/building/${this.building}`})
                
            },


            /**/
            buildingName: function(building) {
                if (building['Building Code'] == building['Building Name']) {
                    return building['Building Name']
                }

                return `(${building['Building Code']}) ${building['Building Name']}`;
            }
        },

        created: function() {
            this.requestCampus();
        }
    }
</script>