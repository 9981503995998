var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SearchModal',{attrs:{"heading":"Search for staff directory of building","loading":_vm.$store.getters.loading}},[_c('form',{staticClass:"form-row",attrs:{"slot":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.requestBuildingStaff()}},slot:"form"},[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.$store.getters.campuses,"item-text":"Campus Name","item-value":"Campus Code","label":"Select a campus","autofocus":"","required":""},on:{"change":function($event){return _vm.requestBuildings()}},model:{value:(_vm.campus),callback:function ($$v) {_vm.campus=$$v},expression:"campus"}})],1),_c('v-col',{attrs:{"cols":"9","md":"5"}},[_c('v-select',{attrs:{"items":_vm.$store.getters.buildings.map((b) => {
                            if (b['Building Code'] == b['Building Name']) {
                                return {
                                    text: b['Building Name'],
                                    value: b['Building Code']
                                }
                            }

                            return {
                                text: `(${b['Building Code']}) ${b['Building Name']}`,
                                value: b['Building Code']
                            }
                        }),"label":"Search building"},model:{value:(_vm.building),callback:function ($$v) {_vm.building=$$v},expression:"building"}})],1),_c('v-col',{attrs:{"cols":"3","md":"1"}},[_c('v-btn',{attrs:{"color":"#903","type":"submit","width":"100%"},on:{"click":function($event){return _vm.requestBuildingStaff()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }